<template>
  <div class="expenses container">

    <!-- ------------------------------- Modal: Expense Details  ------------------------------------------ -->

	<CModal alignment="center" title="Expense Details" color="info" :show.sync="modalExpense">	
		<div slot="header">
			<h5>Expense</h5>
		</div>	  

		<div slot="footer">
			<CButton color="dark" class="mr-3" v-on:click.prevent="serverCall('addUpdateExpense')">Save</CButton>
			<CButton color="dark" class="mr-3" v-on:click.prevent="modalExpense=false">Close</CButton>
			<div>
				<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
				<scale-loader :loading="loading"></scale-loader>
			</div>
		</div>	

		<form>
			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Date</label>
				<datepicker v-model="expense.createDate"></datepicker>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Type</label>
				<!-- <input type="text" class="col-sm-8 form-control" v-model="expense.type"> -->
				<div class="col-sm-8">
					<select class="form-control" v-model="expense.type">
						<option v-for="expType in expTypes" :value="expType">{{expType}}</option>
					</select>
        		</div>
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Amount</label>
				<input type="number" class="col-sm-8 form-control" @focus="$event.target.select()" v-model="expense.amount">
			</div>

			<div class="form-group row">
				<label class="col-sm-2 col-form-label font-weight-bold text-right">Desc</label>
				<input type="text" class="col-sm-8 form-control" v-model="expense.description">
			</div>

		</form>

	</CModal>

 
    <!--------------------------------- Expenses : Search Criteria -------------------------------------------->

	<p class="font-weight-bold" align="center">{{location}} Expenses</p>

	<form class="form">
		<CCard :color="'secondary'">
			<CCardBody>
				<div class="row">
					<label class="font-weight-bold ml-3 mr-2" for="fromDate">From:</label>
					<datepicker v-model="fromDate" name="fromDate"></datepicker>

					<label class="font-weight-bold ml-3 mr-2" for="toDate">To:</label>
					<datepicker v-model="toDate" name="toDate"></datepicker>		
					
					<CButton color="dark" class="ml-4" v-on:click.prevent="serverCall('getExpenses')">Get Expenses</CButton>

				</div>
			</CCardBody>
		</CCard>

	</form>	

    <!-- ---------------------------------------  Main  -------------------------------------------------- -->

	<div>
		<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
		<scale-loader :loading="loading"></scale-loader>
	</div>
	<p class="text-danger" align="left">{{alertMsg}}</p>



   	<!-- ---------------------------------------  Expense CRUD -------------------------------------------------- -->

	<vue-good-table ref="userTable" 
							:columns="colsExpenses" 
							:rows="rowsExpenses" 
							@on-row-click="rowChangedExpense"
							:selectOptions="{
									enabled: true,
									selectOnCheckboxOnly: false, 
							}">
			<div slot="table-actions">
				<CButton color="dark" class="mr-4" v-on:click.prevent="openNewExpense()">New</CButton>
			</div>
			<div slot="selected-row-actions">
				<CButton color="dark" v-on:click.prevent="openExistingExpense()">Details</CButton>
				<CButton color="dark" class="ml-2 mr-2" v-on:click.prevent="serverCall('deleteExpense')">Delete</CButton>
			</div>
	</vue-good-table>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker'
import ScaleLoader from "@/views/utils/ScaleLoader"
import { firebaseAuth } from '../../firebaseConfig.js';
import Helper from "../utils/Helper.vue"
import Multiselect from 'vue-multiselect'

export default {
	name: 'expenses',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
		Multiselect,
	},
		
	data () {
		return {
			orgId: null,
			location: null,

			fromDate: new Date(),
			toDate: new Date(),

            loading: false,
            loadingMsg: null,		
			alertMsg: '',

			expTypes: [],

			modalExpense: false,
			expense: {},
			curRowExpense: null,
			existingExpense: false,

			rowsExpenses: [],
			colsExpenses: [
					{
							label: 'Date',
							field: 'createDate',
							type: 'date',
							//dateInputFormat: '%a, DD MMM YYYY HH:MM:SS GMT', // expects Fri, 07 Feb 2025 12:00:00 GMT
							dateInputFormat: 'yyyy-MM-dd', // expects 2018-03-16
							dateOutputFormat: 'd MMM yy', // outputs Feb 07 2025
							sortable: true,
					},
					{
							label: 'Type',
							field: 'type',
							filterOptions: {'enabled': true},
					},
					{
							label: 'Amount',
							field: 'amount',
					},
					{
							label: 'Description',
							field: 'description',
							filterOptions: {'enabled': true},

					},
			],

            pagination_options: {
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                perPageDropdown: [10, 20, 30, 40],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
			},
		}
	},

	computed: {
	},

	watch: {
	},

	mounted(){
		this.orgId = this.$route.params['orgId']
		this.location = this.$route.params['location']
		this.expTypes = this.serverCall('getExpenseTypes')
		this.serverCall('getExpenses')
	},


	methods: {
        serverCall(name) {
            var self = this

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getExpenseTypes":
								self.getExpenseTypes(idToken)
								break;

							case "getExpenses":
								self.getExpenses(idToken)
								break;

							case "addUpdateExpense":
								if (!self.expense.type || !self.expense.amount) {
									self.alertMsg = 'Both Type and Amount need to be specified.'
									return
								}
								self.addUpdateExpense('Update', self.expense, idToken)


								// var rowsTemp = self.rowsExpenses.slice()
								// // Prepare new user list
								// if (self.existingExpense){
								// 	rowsTemp[self.curRowExpense.originalIndex] = self.expense
								// }
								// else{
								// 	rowsTemp.push(self.expense)
								// }

								// // Update rowsDBcols
								// self.rowsExpenses = rowsTemp

								// if (self.existingExpense)
								// 	self.addUpdateExpense('Update', self.expense, idToken)
								// else
								// 	self.addUpdateExpense('Add', self.expense, idToken)

								break

							case "deleteExpense":
								// Check if expense is selected
								if (self.curRowExpense==null){
									self.alertMsg = "Expense not selected."
									return
								}
								this.$confirm("Expense will be deleted. Are you sure?").then(() => {
									//var expenseIndex = self.curRowExpense['originalIndex']
									//self.rowsExpenses.splice(expenseIndex, 1)
									self.deleteExpense(self.curRowExpense, idToken)
								})
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alertMsg = "Please login first."
				}
			})
		},

		// ************* Expenses ************

		getExpenseTypes(authToken) {
            var self = this
			self.alertMsg = null

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching ExpensTypes..."

			var postJson = {
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetExpenseTypes/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alertMsg = response.data.err
					}
					else {
						self.expTypes = response.data.expenseTypes
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alertMsg = String(error)
				})
		},

        getExpenses(authToken) {
            var self = this
			self.alertMsg = null

            if (!this.fromDate || !this.toDate || !this.location){
                self.alertMsg = "Either fromDate or toDate or location not specified."
                return
			}
            if (this.fromDate > this.toDate){
                self.alertMsg = "From Date should be less than To Date."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Expense..."

			var postJson = {
				'location': this.location,
				'fromDate': this.getMidDay(this.fromDate),
				'toDate': this.getMidDay(this.toDate),
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetExpenses/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					if (response.data.err != ''){
						self.alertMsg = response.data.err
					}
					else {
						self.rowsExpenses = response.data.expenseList
					}
				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					
					self.alertMsg = String(error)
				})
		},


		// Save expense changes - for new as well as existing expenses
        addUpdateExpense(opType, localExpense, authToken) {
            var self = this
            this.alertMsg = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Adding/Updating Expense..."

			// var formData = new FormData()
			// formData.append('expense', JSON.stringify(localExpense))
			// formData.append('opType', opType)

			var postJson = {
				'location': this.location,
				'expense': JSON.stringify(localExpense),
				'opType': opType,
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/AddUpdateExpense/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'location': self.location,
						'Content-Type': 'application/json',
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertMsg = response.data.err
					}
					else {
						self.modalExpense = false
						self.serverCall('getExpenses')
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.alertMsg = String(error)
				})

		},

		// Delete expense changes - for new as well as existing expenses
        deleteExpense(localExpense, authToken) {
            var self = this
            this.alertMsg = ""

			// Set loading message
			self.loading = true
			self.loadingMsg = "Deleting Expense..."

			var postJson = {
				'location': this.location,
				'expense': JSON.stringify(localExpense)
			}

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/DeleteExpense/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'location': self.location,
						'Content-Type': 'application/json',
					}
				})
				.then(function (response) {
					if (response.data.err != '') {
						self.alertMsg = response.data.err
					}
					else {
						self.modalExpense = false
						self.serverCall('getExpenses')
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null

				})
				.catch(function (error) {
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
					self.alertMsg = String(error)
				})

		},

		rowChangedExpense(params){	
			// Current selected Expense
			if (this.curRowExpense != null){
				// If new row is not same as previous row, uncheck previous row
				if (this.curRowExpense.originalIndex!=params.row.originalIndex)
					this.curRowExpense.vgtSelected = false
			}
			// New selected row
			this.curRowExpense = params.row
			this.expense= this.curRowExpense
		},

		// Open details for existing Expense
		openExistingExpense(){
			this.alertMsg = null

            // Check if expense is selected
			if (this.curRowExpense==null){
				this.alertMsg = "Expense not selected."
				return
			}
			this.expense = this.curRowExpense
			this.existingExpense = true
			this.modalExpense = true
		},

		// Open details for new Expense
		openNewExpense(){
			this.alertMsg = null

			// Initialize user object
			this.expense = {
				createDate: this.getMidDay(new Date()),
				location: this.location,
				type: 'Misc',
				amount: 0.0,
				description: '',
				vgtSelected: false
			}

			this.existingExpense = false
			this.modalExpense = true
		},

	},

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
</style>

